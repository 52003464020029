/* ----if this is used tab list dont remains static and scrolls below header ----  */
.static-bar {
  padding: 15px 25px 1px 25px !important;
  position: sticky;
  top: 70px;
  z-index: 100;
}

.container .ant-tabs {
  margin-left: -34px !important;
  margin-right: -34px !important;
}

.static-tabs-list {
  margin-top: 74px !important;
}

.static-tabs-list .ant-tabs-nav-list {
  padding-left: 34px !important;
  padding-right: 34px !important;
}

.static-tabs-list .ant-tabs-nav {
  background-color: white;
  position: sticky !important;
  z-index: 99;
  /* top: 0px !important; */
  top: 157.5px !important;
  margin-left: -25px !important;
  margin-right: -25px !important;
}

.static-tabs-list .ant-tabs-content-holder {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

/* ---------- tab list design which scrolls ends ---------- */

/* ----if this is used tab list also remains static but inbuilt horizontal scroll of tablist breaks ----  */
/* .static-bar {
  padding: 15px 25px 10px 25px !important;
  position: sticky;
  top: 70px;
  z-index: 100;
}

.container .ant-tabs {
  margin-left: -34px !important;
  margin-right: -34px !important;
}


.static-tabs-list {
  margin-top: 70px !important;
}

.static-tabs-list .ant-tabs-nav-list {
  padding-left: 34px !important;
  padding-right: 34px !important;

}

.static-tabs-list .ant-tabs-nav-wrap {
  position: fixed !important;
  top: 142.8px !important;
  z-index: 101 !important;
  background-color: white;
  width: 100% !important;
  border-bottom: 1px solid #e6ebf1 !important;
}

.static-tabs-list .ant-tabs-nav-wrap, .ant-tabs-nav-operations {
  z-index: 102 !important;
}

.static-tabs-list .ant-tabs-content-holder {
  margin-top: 30px !important;
  margin-left: 34px !important;
  margin-right: 34px !important;
} */

/* ---------- tab list design without horizontal scroll ends ---------- */

.sun-editor .se-wrapper .se-wrapper-wysiwyg {
  display: block;
  height: 350px !important;
}

/* .reactEasyCrop_Container {
  width: 700px !important;
  height: 500px !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  user-select: none;
  touch-action: none;
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.zoom-modal .ant-modal-content {
  position: relative;
  background-color: transparent !important;
  background-clip: padding-box;
  border: 0px;
  /* border-radius: 0.625rem; */
  box-shadow: none !important;
  pointer-events: auto;
}

.zoom-modal .ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: 0px 0px;
  border-top: none !important;
  border-radius: 0px 0px 0.625rem 0.625rem;
}

.phone-ant-input .PhoneInputInput {
  flex: 1;
  box-sizing: border-box;
  margin: 0px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0px;
  padding: 8.5px 11px;
  color: rgb(69, 85, 96);
  font-size: 14px;
  line-height: 1.5;
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid rgb(230, 235, 241);
  border-radius: 0.625rem;
  transition: all 0.3s ease 0s;
}

.description-limit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 200px;
}

.description-limit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 200px;
}

.ant-input:disabled {
  color: black;
}

.image-cropper-card .ant-card .ant-card-body {
  padding: 0 !important;
}

/* .page-youtube
  :where(.css-dev-only-do-not-override-10jqpyg)[class^="ant-space"]
  [class^="ant-space"],
:where(.css-dev-only-do-not-override-10jqpyg)[class*=" ant-space"]
  [class^="ant-space"],
:where(.css-dev-only-do-not-override-10jqpyg)[class^="ant-space"]
  [class*=" ant-space"],
:where(.css-dev-only-do-not-override-10jqpyg)[class*=" ant-space"]
  [class*=" ant-space"] {
    width: 100% !important;
} */

.ant-space-align-baseline .ant-space-item:first-of-type{
  width: 100% !important;
}
